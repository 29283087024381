<template>
    <div class="admin">
        <div class="level is-vcentered">
            <div class="level-left">
                <h1 class="title is-size-4">Administration</h1>
            </div>
            <div class="level-right">
                <router-link class="level-item button is-outlined" to="/">Back</router-link>
            </div>
        </div>
        <div class="tile is-ancestor">
            <router-link v-for="item in filteredAdminList" :key="item.title" class="tile is-parent is-4" :to="item.pageLink">
                <div class="tile is-child box has-text-centered has-hover-shadow">
                    <p class="title is-size-4">{{ item.title }}</p>
                    <p class="subtitle">{{ item.description }}</p>
                    <v-icon :name="item.icon" scale=4></v-icon>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Admin',
    data () {
        return {
            adminList: [
                { title: 'Users', sysName: 'Users', description: 'Manage Users', icon: 'users', pageLink: '/admin/users' },
                { title: 'Logs', sysName: 'Logs', description: 'Export logs for Indigo', icon: 'file', pageLink: '/admin/logs' },
                { title: 'Document', sysName: 'Documents', description: 'Edit Help Section', icon: 'question-circle', pageLink: '/admin/docs' }
            ]
        };
    },
    computed: {
        filteredAdminList: function () {
            return this.adminList.filter((item) => this.$ability.can('manage', item.sysName));
        }
    }
};
</script>

<style lang="scss" scoped>
.tile.is-ancestor {
    flex-wrap:wrap;
}
.icon {
  font-size: 30px;
}
</style>

<template>
    <div>
        <div class="level is-vcentered">
            <div class="level-left">
                <h1 class="title is-size-4">Logs</h1>
            </div>
            <div class="level-right">
                <router-link class="level-item button is-outlined" to="/Admin">Back to Admin</router-link>
            </div>
        </div>

        <section class="">
            <div class="">
                <b-field grouped>
                    <b-field label="From Date (UTC)">
                        <b-datepicker placeholder="Select a date..."
                                      :min-date="dateLimit.start"
                                      :max-date="datePickerDateRange.end"
                                      v-model="datePickerDateRange.start"
                                      icon="fa fa-calendar">
                        </b-datepicker>
                    </b-field>
                    <b-field label="To Date (UTC)">
                        <b-datepicker placeholder="Select a date..."
                                      :min-date="datePickerDateRange.start"
                                      :max-date="dateLimit.end"
                                      v-model="datePickerDateRange.end"
                                      icon="fa fa-calendar">
                        </b-datepicker>
                    </b-field>
                </b-field>
                <div class="level-item">
                    <b-button
                        @click="exportLoginAttempts"
                        icon-left="file-csv"
                        size="is-medium"
                        :disabled="isExporting"
                        >
                        Download Login Attempts

                        <i v-if="isExporting" class="fas fa-spinner fa-spin is-small"></i>
                    </b-button>
                </div>

            </div>
        </section>

    </div>
</template>

<script>
import moment from 'moment';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';

import LogsService from '@/services/logs-service';
import { dateHelperService } from '@/services/date-helper-service';

export default {
    name: 'Logs',
    data () {
        return {
            // buefy datepicker uses local date/time (time is fixed at 00:00:00). As we want users to see the UTC day,
            // we will only keep the date value and convert it to a Local date so the right day will be displayed on the datepicker
            dateLimit: {
                start: dateHelperService.convertToLocal('2019-01-01'),
                end: dateHelperService.convertToLocal(new Date())
            },

            // Use the computed utcDateRange to do any calculations or data fetching
            datePickerDateRange: {
                start: dateHelperService.convertToLocal(moment().subtract(30, 'days')),
                end: dateHelperService.convertToLocal(new Date())
            },
            isExporting: false
        };
    },
    computed: {
        utcDateRange () {
            return {
                start: dateHelperService.convertToUTC(this.datePickerDateRange.start),
                end: dateHelperService.convertToUTC(this.datePickerDateRange.end)
            };
        }
    },
    methods: {
        async exportLoginAttempts () {
            try {
                this.isExporting = true;
                const logsResult = await LogsService.getAccessLogs(this.utcDateRange.start, this.utcDateRange.end);

                const csvBody = Papa.unparse(logsResult.data);
                const csvBlob = new Blob([csvBody], { type: 'text/plain;charset=utf-8' });
                saveAs(csvBlob, 'IndigoAccessLogs.csv');
            } catch (error) {

            } finally {
                this.isExporting = false;
            }
        }
    }
};
</script>

<template>
    <div>
        <div class="level is-vcentered">
            <div class="level-left">
                <h1 class="title is-size-4">Users</h1>
            </div>
            <div class="level-right">
                <button class="level-item button is-primary is-outlined"
                    @click="showAddUserForm">
                    Add New User
                </button>
                <b-button
                        class="level-item button is-primary is-outlined"
                        @click="exportUserList"
                        icon-left="file-csv"

                        :disabled="isExporting"
                        >
                        Download User List
                        <i v-if="isExporting" class="fas fa-spinner fa-spin is-small"></i>
                    </b-button>
                <router-link class="level-item button is-outlined" to="/Admin">Back to Admin</router-link>
            </div>
        </div>
        <b-notification type="is-danger" v-if="errorMessage" :closable="false">
            {{ errorMessage }}
        </b-notification>
        <!-- Search input -->
        <b-field>
            <b-input
                rounded
                v-model="searchName"
                placeholder="search..."
                icon="search"
            >
            </b-input>
        </b-field>
        <!-- User Table -->
        <b-table
            class="box"
            :data="filteredUsers"
            :paginated="true"
            :per-page="usersPerPage"
            :current-page.sync="currentPage"
            :pagination-simple="false"
            :pagination-position="'both'"
            :default-sort-direction="'asc'"
            :striped="true"
            :loading="isLoading"
            default-sort="id"
        >
            <template v-slot:bottom-left>
                {{pageInfo}}
            </template>

            <template>
                <b-table-column field="email" label="Email" sortable v-slot="props">
                    {{props.row.email}}
                </b-table-column>
                <b-table-column field="country" label="Country" sortable v-slot="props">
                    {{ props.row.country }}
                </b-table-column>
                <b-table-column field="company" label="Company" sortable v-slot="props">
                    {{ props.row.company }}
                </b-table-column>
                <b-table-column field="role" label="Role" sortable v-slot="props">
                    {{ props.row.role }}
                </b-table-column>
                <b-table-column field="isActive" label="Is Active?" centered sortable v-slot="props">
                    <div>
                        <b-icon
                            :icon="props.row.isActive ? 'check' : 'times'"
                            :type="props.row.isActive ? 'is-success' : 'is-danger'">
                        </b-icon>
                        {{ props.row.isActive ? 'Yes' : 'No' }}
                    </div>
                </b-table-column>
                <b-table-column field="isLionLogin" label="Is Lion Login?" centered sortable v-slot="props">
                    <div>
                        <b-icon
                            :icon="props.row.isLionLogin ? 'check' : 'times'"
                            :type="props.row.isLionLogin ? 'is-success' : 'is-danger'">
                        </b-icon>
                        {{ props.row.isLionLogin ? 'Yes' : 'No' }}
                    </div>
                </b-table-column>
                <b-table-column field="lastModifiedBy" label="Last Modified By" sortable v-slot="props">
                    {{ props.row.lastModifiedBy }}
                </b-table-column>
                <b-table-column field="lastModified" label="Last Modified (UTC)" sortable v-slot="props">
                    {{ props.row.lastModified | formatDate }}
                </b-table-column>
                <b-table-column label="Edit" width="100" v-slot="props">
                    <button class="level-item button is-outlined" @click="showEditUserForm(props.row)" v-if="canEditOrDeleteUser(props.row)">
                        <b-icon
                            icon="edit"
                            type="is-primary"
                        >
                        </b-icon>
                    </button>
                </b-table-column>
                <b-table-column label="Deactivate" width="100" v-slot="props">
                    <button class="level-item button is-outlined"
                        @click="props.row.isActive ? showConfirmDeactivateModal(props.row) : showConfirmReactivateModal(props.row)" v-if="canEditOrDeleteUser(props.row)">
                        <b-icon
                            :icon="props.row.isActive ? 'user-slash' : 'user-check'"
                            :type="props.row.isActive ? 'is-danger' : 'is-success'"
                        >
                        </b-icon>
                    </button>

                </b-table-column>

            </template>
            <!-- Empty Table Template -->
            <template slot="empty">
                <empty-table-content/>
            </template>
        </b-table>

    </div>
</template>

<script>
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import _ from 'lodash';

import UserService from '@/services/user-service.js';
import UserForm from '@/components/UserForm.vue';
import EmptyTableContent from '@/components/EmptyTableContent.vue';

export default {
    name: 'UsersAdmin',
    components: {
        EmptyTableContent
    },
    data () {
        return {
            users: [],
            filteredUsers: [],
            availableRolesForCurrentUser: [],
            searchName: '',
            errorMessage: '',
            isLoading: true,
            isExporting: false,
            usersPerPage: 25,
            currentPage: 1
        };
    },
    async created () {
        try {
            const results = await Promise.all([
                UserService.getUsers(),
                UserService.getRoles()
            ]);
            const [users, availableUserRoles] = results;

            if (users) {
                this.users = users.data;
                this.filteredUsers = users.data;
            }
            if (availableUserRoles) {
                this.availableRolesForCurrentUser = availableUserRoles.data;
            }
        } catch (error) {
            this.errorMessage = error.message;
        } finally {
            this.isLoading = false;
        }
    },
    methods: {
        deactivateUser (userToDeactivate) {
            UserService.deactivateUser(userToDeactivate.id)
                .then(response => {
                    userToDeactivate.isActive = false;
                    this.$buefy.toast.open({
                        message: userToDeactivate.email + ' has been deactivated',
                        type: 'is-success'
                    });
                })
                .catch(this.showErrorToast);
        },
        reactivateUser (userToActivate) {
            UserService.reactivateUser(userToActivate.id)
                .then(response => {
                    userToActivate.isActive = true;
                    this.$buefy.toast.open({
                        message: userToActivate.email + ' has been activated',
                        type: 'is-success'
                    });
                },
                this.showErrorToast
                );
        },
        showEditUserForm (userToEdit) {
            this.$buefy.modal.open({
                parent: this,
                component: UserForm,
                hasModalCard: true,
                props: {
                    isUpdate: true,
                    userToEdit: userToEdit
                },
                events: {
                    submitEvent: (updatedUser) => this.updateUser(userToEdit, updatedUser)
                }
            });
        },
        updateUser (userToEdit, updatedUser) {
            Object.assign(userToEdit, updatedUser);
            this.$buefy.toast.open({
                message: updatedUser.email + ' has been updated',
                type: 'is-success'
            });
        },
        showAddUserForm () {
            this.$buefy.modal.open({
                parent: this,
                component: UserForm,
                hasModalCard: true,
                props: {
                    isUpdate: false
                },
                events: {
                    submitEvent: this.addUser
                }
            });
        },
        addUser (newUser) {
            this.users.push(newUser);
            this.$buefy.toast.open({
                message: newUser.email + ' has been added',
                type: 'is-success'
            });
        },
        showConfirmDeactivateModal (userToDeactivate) {
            this.$buefy.dialog.confirm({
                title: 'Deactivate User',
                message: 'Are you sure you want to deactivate <b>' + userToDeactivate.email + '</b>?',
                onConfirm: () => this.deactivateUser(userToDeactivate)
            });
        },
        showConfirmReactivateModal (userToReactivate) {
            this.$buefy.dialog.confirm({
                title: 'Activate User',
                message: 'Are you sure you want to activate <b>' + userToReactivate.email + '</b>?',
                onConfirm: () => this.reactivateUser(userToReactivate)
            });
        },
        showErrorToast (error) {
            let msg;
            if (error.response && error.response.data) {
                msg = error.response.data;
            } else {
                msg = error.message;
            }
            this.$buefy.toast.open({
                message: msg,
                type: 'is-danger'
            });
        },
        canEditOrDeleteUser (user) {
            // Can only edit/delete if the given user role is present in the available roles for the current logged in user,
            // which means a user can edit/delete users who are below their role.
            return _.some(this.availableRolesForCurrentUser, (r) => r === user.role);
        },
        async exportUserList () {
            try {
                this.isExporting = true;
                const nicerUserResult = _.map(this.users, u => ({
                    Email: u.email,
                    FirstName: u.firstName,
                    LastName: u.lastName,
                    Country: u.country,
                    Company: u.company,
                    Role: u.role,
                    IsActive: u.isActive,
                    IsLionLogin: u.isLionLogin,
                    Created: u.created,
                    LastModified: u.lastModified,
                    LastModifiedBy: u.lastModifiedBy
                }));

                const csvBody = Papa.unparse(nicerUserResult);
                const csvBlob = new Blob([csvBody], { type: 'text/plain;charset=utf-8' });
                saveAs(csvBlob, 'IndigoUserList.csv');
            } catch (error) {

            } finally {
                this.isExporting = false;
            }
        },
        debounceFilterUsers: _.debounce(function () { this.filterUsers(); }, 250),
        filterUsers: function () {
            const substring = this.searchName.toLowerCase();
            this.filteredUsers = this.users.filter(u => {
                return u.email.toLowerCase().includes(substring) ||
                    u.company.toLowerCase().includes(substring) ||
                    u.country.toLowerCase().includes(substring) ||
                    u.lastModifiedBy.toLowerCase().includes(substring);
            });
        }

    },
    watch: {
        searchName: function (newValue, oldValue) {
            this.debounceFilterUsers();
        }
    },
    computed: {
        pageInfo: function () {
            const totalUsers = this.filteredUsers.length;
            const startRange = (this.currentPage - 1) * this.usersPerPage + 1;
            const endRange = Math.min(this.currentPage * this.usersPerPage, totalUsers);
            return `Showing ${startRange} to ${endRange} of ${totalUsers} users`;
        }
    }
};
</script>

<style lang="scss" scoped>
.box {
    overflow: auto;
}
.table td {
    vertical-align: middle;
}
</style>

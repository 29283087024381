<template>
    <div>
        <div class="level is-vcentered">
            <div class="level-left">
                <h1 class="title is-size-4">Documents</h1>
            </div>
            <div class="level-right">
                <button class="level-item button is-primary is-outlined"
                    @click="showAddDocumentForm">
                    Add New Document
                </button>
                <router-link class="level-item button is-outlined" to="/Admin">Back to Admin</router-link>
            </div>
        </div>

        <b-table
            class="box"
            :data="documents"
            :pagination-simple="false"
            :default-sort-direction="'asc'"
            :striped="true"
            :loading="isLoading"
            default-sort="id"
        >
            <template>
                <b-table-column field="name" label="Name" sortable v-slot="props">
                    {{ props.row.name }}
                </b-table-column>
                <b-table-column field="url" label="Url" sortable v-slot="props">
                    <a :href="props.row.url" target="_blank">{{props.row.url}}</a>
                </b-table-column>
                <b-table-column label="Delete" width="100" v-slot="props">
                    <button class="level-item button is-outlined"
                        @click="showConfirmDeleteDocumentModal(props.row)">
                        <b-icon icon="trash" type="is-danger">
                        </b-icon>
                    </button>
                </b-table-column>
            </template>
            <!-- Empty Table Template -->
            <template slot="empty">
                <p>
                    No links available
                </p>
            </template>
        </b-table>

    </div>
</template>

<script>
import { EventBus } from '@/event-bus';
import documentService from '@/services/document-service.js';
import DocumentForm from '@/components/DocumentForm.vue';

export default {
    name: 'Documents',
    data () {
        return {
            documents: [],
            errorMessage: '',
            isLoading: true
        };
    },
    created () {
        documentService.getAll()
            .then(
                response => {
                    this.documents = response.data;
                }
            ).catch(
                error => {
                    this.errorMessage = error.message;
                }
            ).finally(
                () => {
                    this.isLoading = false;
                }
            );
    },
    methods: {
        showAddDocumentForm () {
            this.$buefy.modal.open({
                parent: this,
                component: DocumentForm,
                hasModalCard: true,
                props: {
                    isUpdate: false
                },
                events: {
                    submitEvent: this.addDocument
                }
            });
        },
        showConfirmDeleteDocumentModal (doc) {
            this.$buefy.dialog.confirm({
                title: 'Remove link',
                message: 'Are you sure you want to delete <b>' + doc.name + '</b>?',
                onConfirm: () => this.deleteDocument(doc)
            });
        },
        addDocument (newDocument) {
            this.documents.push(newDocument);
            EventBus.$emit('documentsChanged', this.documents);
            this.$buefy.toast.open({
                message: newDocument.name + ' has been added',
                type: 'is-success'
            });
        },
        deleteDocument (document) {
            this.isLoading = true;
            documentService.delete(document.id)
                .then(response => {
                    this.documents.splice(this.documents.indexOf(document), 1);
                    EventBus.$emit('documentsChanged', this.documents);
                    this.$buefy.toast.open({
                        message: document.name + ' has been removed',
                        type: 'is-success'
                    });
                }).catch(
                    error => {
                        this.errorMessage = error.message;
                    }
                ).finally(
                    () => {
                        this.isLoading = false;
                    }
                );
        }
    }
};
</script>

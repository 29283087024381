import axios from '@/services/axios-auth-wrapper';

export default {
    getUsers: () => {
        return axios.get(url);
    },
    getRoles: () => {
        return axios.get(url + 'roles');
    },
    updateUser: (data) => {
        return axios.post(url + 'update', data);
    },
    addUser: (data) => {
        return axios.post(url + 'create', data);
    },
    put: (url, data) => {
        return axios.put(url, data);
    },
    deactivateUser: (userId) => {
        return axios.delete(url + userId);
    },
    reactivateUser: (userId) => {
        return axios.post(url + 'reactivate/' + userId);
    }
};

const url = process.env.VUE_APP_INDIGO_API_URL + '/api/user/';

<template>
<form @submit.prevent="submitForm">
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">Add Document</p>
            </header>
            <section class="modal-card-body">
                <b-notification type="is-danger" v-if="errorMessage" :closable="false">
                    {{ errorMessage }}
                </b-notification>
                <b-field label="Name" horizontal>
                    <b-input
                        type="text"
                        v-model="document.name"
                        required>
                    </b-input>
                </b-field>
                <b-field label="Url" horizontal>
                    <b-input
                        type="text"
                        v-model="document.url"
                        required>
                    </b-input>
                </b-field>
            </section>
            <footer class="modal-card-foot">
                <button type="submit" class="button is-primary">Add</button>
                <button  type="button" class="button" @click="onCancelAction">Cancel</button>
            </footer>
        </div>
    </form>
</template>

<script>
import documentService from '@/services/document-service';

export default {
    name: 'DocumentForm',
    props: {
        isUpdate: {
            type: Boolean,
            required: true
        }
    },
    data () {
        return {
            errorMessage: '',
            roles: [],
            document: {
                name: '',
                url: ''
            }
        };
    },
    methods: {
        submitForm () {
            documentService.create(this.document)
                .then(
                    response => {
                        this.$emit('submitEvent', response.data);
                        this.$parent.close();
                    },
                    error => {
                        if (error.response && error.response.data) {
                            this.errorMessage = error.response.data;
                        } else {
                            this.errorMessage = error.message;
                        }
                    }
                );
        },
        onCancelAction () {
            this.$parent.close();
        }
    }
};
</script>

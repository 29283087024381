import axios from '@/services/axios-auth-wrapper';
import { dateHelperService } from '@/services/date-helper-service';

export default {
    getAccessLogs: (startDate, endDate) => {
        const apiStartDate = dateHelperService.formatForAPI(startDate);
        const apiEndDate = dateHelperService.formatForAPI(endDate);

        return axios.get(`${url}/access?startDate=${apiStartDate}&endDate=${apiEndDate}`);
    }
};

const url = process.env.VUE_APP_INDIGO_API_URL + '/api/logs';

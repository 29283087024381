<template>
    <form @submit.prevent="submitForm">
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">{{ isUpdate ? 'Edit User' : 'Add User' }}</p>
            </header>
            <section class="modal-card-body">
                <b-notification type="is-danger" v-if="errorMessage" :closable="false">
                    {{ errorMessage }}
                </b-notification>
                <b-field label="Email" horizontal>
                    <b-input v-if="!user.isLionLogin"
                        type="email"
                        v-model="user.email"
                        required>
                    </b-input>
                    <p v-if="user.isLionLogin"> {{ user.email }}</p>
                </b-field>
                <b-field label="First Name" horizontal>
                    <b-input v-if="!user.isLionLogin"
                        type="text"
                        v-model="user.firstName"
                        required>
                    </b-input>
                    <p v-if="user.isLionLogin"> {{ user.firstName }}</p>
                </b-field>
                <b-field label="Last Name" horizontal>
                    <b-input v-if="!user.isLionLogin"
                        type="text"
                        v-model="user.lastName"
                        required>
                    </b-input>
                    <p v-if="user.isLionLogin"> {{ user.lastName }}</p>
                </b-field>
                <b-field v-if="user.isLionLogin" label="Short Name" horizontal>
                    <p> {{ user.shortName }}</p>
                </b-field>
                <b-field label="Country" horizontal>
                    <b-input v-if="!user.isLionLogin"
                        type="text"
                        v-model="user.country"
                        required>
                    </b-input>
                    <p v-if="user.isLionLogin"> {{ user.country }}</p>
                </b-field>
                <b-field label="Company" horizontal>
                    <b-input v-if="!user.isLionLogin"
                        type="text"
                        v-model="user.company"
                        required>
                    </b-input>
                    <p v-if="user.isLionLogin"> {{ user.company }}</p>
                </b-field>
                <b-field v-if="user.isLionLogin" label="HFM Code" horizontal>
                    <p>{{ user.hfmCode }}</p>
                </b-field>
                <b-field label="Role" horizontal>
                    <b-select v-model="user.role" placeholder="Select Role" required>
                        <option v-for="(role, index) in roles" :key="`role-${index}`" :value="role">{{ role }}</option>
                    </b-select>
                </b-field>
                <b-field horizontal>
                    <b-checkbox v-model="user.isActive">Is Active?</b-checkbox>
                </b-field>
                <b-field horizontal>
                    <b-checkbox v-model="user.isLionLogin" :disabled="isClientRoleSelected">Is Lion Login?</b-checkbox>
                </b-field>
                <b-field v-if="isUpdate" label="Created (UTC)" horizontal>
                    <p>{{ user.created | formatDate }}</p>
                </b-field>
            </section>
            <footer class="modal-card-foot">
                <button  type="button" class="button" @click="onCancelAction">Cancel</button>
                <button type="submit" class="button is-primary">{{ isUpdate ? 'Save' : 'Add' }}</button>
            </footer>
        </div>
    </form>
</template>

<script>
import UserService from '@/services/user-service.js';

export default {
    name: 'UserForm',
    props: {
        isUpdate: {
            type: Boolean,
            required: true
        },
        userToEdit: {
            type: Object,
            required: false
        }
    },
    data () {
        return {
            errorMessage: '',
            roles: [],
            user: {
                email: '',
                firstName: '',
                lastName: '',
                shortName: '',
                country: '',
                company: '',
                role: null,
                isActive: true,
                isLionLogin: false
            }
        };
    },
    created () {
        if (this.userToEdit) {
            this.user = Object.assign(this.user, this.userToEdit);
        }

        UserService.getRoles()
            .then(
                response => {
                    this.roles = response.data;
                },
                error => {
                    this.errorMessage = error.message;
                }
            );
    },
    methods: {
        submitForm () {
            const submitAction = this.isUpdate ? UserService.updateUser : UserService.addUser;
            submitAction(this.user)
                .then(
                    response => {
                        this.$emit('submitEvent', response.data);
                        this.$parent.close();
                    },
                    error => {
                        if (error.response && error.response.data) {
                            // TODO: Check what the data contains - seems to return an html page sometimes
                            // Meant to contain the validation results
                            this.errorMessage = error.response.data;
                        } else {
                            this.errorMessage = error.message;
                        }
                    }
                );
        },
        onCancelAction () {
            this.$parent.close();
        }
    },
    computed: {
        isClientRoleSelected () {
            return this.user.role === 'Client';
        }
    }
};
</script>
